@if(Form){
  <div class="authForm-wrapper">
    <h3>Sign Up</h3>
    
    @if(!otpSent){
      <p>Check your {{usernameType}} to get 4 digit OTP pin to complete signup</p>
      <form [formGroup]="Form" (ngSubmit)="sendOtp()" class="form custom-scrollbar">
        <!-- <app-otp-field (otpSubmit)="getOtp($event)"></app-otp-field> -->
        <mat-form-field appearance="outline" class="username-fld full-width">
          <mat-label>First Name</mat-label>
          <input matInput placeholder="Enter first name" type="text"
            formControlName="firstName">
        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Last Name</mat-label>
          <input matInput placeholder="Enter last name" type="text"
            formControlName="lastName">
        </mat-form-field>
  
  
        <mat-form-field appearance="outline" class="username-fld full-width">
          <mat-label>Mobile No</mat-label>
          <input [class.pl-3]="Form.get('mobileNumber')?.value.length > 0" matInput placeholder="Enter mobile number" type="text"
            formControlName="mobileNumber">
            @if(Form.get('mobileNumber')?.value.length > 0){
              <span matPrefix class="numPrefix">+1</span>
            }
          <mat-icon matSuffix>call</mat-icon>
        </mat-form-field>
  
  
        <!-- Canabis fields (Starts) -->
        
         @if(basicInfo && basicInfo.businessType == 'cannabis'){
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Date of Birth</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="dateOfBirth" required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            @if(Form.get('dateOfBirth')?.hasError('required')){
              <mat-error>
                Date of Birth is required
              </mat-error>
            }
          </mat-form-field>
           
          
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Customer Type</mat-label>
            <mat-select formControlName="customerType" required>
              <mat-option value="Medical">Medical</mat-option>
              <mat-option value="Recreational">Recreational</mat-option>
              <mat-option value="Processor">Processor</mat-option>
            </mat-select>
            @if(Form.get('customerType')?.hasError('required')){
              <mat-error>
                Customer Type is required
              </mat-error>
            }
          </mat-form-field>
           
          @if(Form.get('customerType')?.value === 'Medical'){
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>MMJ State ID Number</mat-label>
              <input matInput type="text" formControlName="mmjStateIDNumber">
            </mat-form-field>
          }
        }
        <!-- Canabis fields (Ends) -->
  
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Enter your Email Address</mat-label>
          <input matInput type="email" formControlName="emailId" autocomplete="off" required />
        </mat-form-field>
         
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Set password</mat-label>
          <input matInput 
          [type]="hide ? 'password' : 'text'" formControlName="password" autocomplete="new-password">
          <button mat-icon-button matSuffix type="button"
          (click)="hide = !hide" 
          [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide">
              <mat-icon class="material-icons-outlined">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
  
        <div class="terms">
          <mat-checkbox formControlName="terms" color="primary">
          </mat-checkbox>
          I have read and accept the 
          <a [routerLink]="['/terms']" target="_blank" class="opacity-5 foreground">Terms and Condition</a>
        </div>
  
        <button mat-flat-button class="btn full-width" 
        [disabled]="!Form.valid || loading"
        >
          <span [class.vHidden]="loading">Signup</span>
          @if(loading){
          <mat-spinner color="primary" diameter="20"></mat-spinner>
          }
        </button>
        
      </form>
    }
    
    @if(otpSent){
      <div class="form-container">
        <p>Check your Mobile or Email to get 4 digit OTP pin for register.</p>
        <form [formGroup]="VerifySignupForm" (ngSubmit)="onVerifySignup()">
          <app-otp-field (otpSubmit)="getOtp($event)"></app-otp-field>
          <button mat-flat-button class="full-width" color="primary" [disabled]="!VerifySignupForm.valid || loading">
              <span [class.vHidden]="loading">Complete Signup</span>
              <mat-spinner color="primary" *ngIf="loading" diameter="20"></mat-spinner>
          </button> 
        
          <div class="secondary-action foreground-5">
              Didn`t receive code? <a class="accent" (click)="sendOtp()">Request Again</a>
          </div>

        </form>
      </div>
    }

  </div>
}