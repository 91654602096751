import { Component, Inject, OnInit } from '@angular/core';
import { LandingComponent } from './landing/landing.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { OtpSignupComponent } from './otp-signup/otp-signup.component';
import { DesignUtilityServices } from '../../appServices/design-utility.service';
import { LoginByPasswordComponent } from './login-by-password/login-by-password.component';
import { LoginByOtpComponent } from './login-by-otp/login-by-otp.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { AuthService } from '../../appServices/auth/auth.service';

@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [
    MatIconModule,
    LandingComponent,
    OtpSignupComponent,
    LoginByPasswordComponent,
    LoginByOtpComponent,
    ForgetPasswordComponent
  ],
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss'
})
export class AuthComponent implements OnInit {
  authStep:string = 'landing';

  constructor(
    public dialogRef: MatDialogRef<LandingComponent>,
    private _du:DesignUtilityServices,
    private authService:AuthService,
    @Inject(MAT_DIALOG_DATA) public data:any
  ){
    this.subscribeAuthStep();
    this.subscribeCloseLoginDialogClick();
  }

  ngOnInit(): void {
    if(this.data){
      console.log('audata ', this.data)
      this.authService.updateAuthStep(this.data.authStep);
      this.authService.setUserName(this.data.userData);
    }else{
      this.authService.updateAuthStep('landing');
    }
    // this.authService.updateAuthStep('forget-password');
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  goBack() {
    this.authService.goBack();
  }


  subscribeAuthStep(){
    this.authService.authStep.subscribe(res=>{
      this.authStep = res;
    });
  }


  subscribeCloseLoginDialogClick(){
    this.authService.openLoginDialogClick.subscribe(res=>{
      this.onNoClick();
    });
  }


}
