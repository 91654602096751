import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import { DesignUtilityServices } from '../../../appServices/design-utility.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { OtpFieldComponent } from '../../otp-field/otp-field.component';
import { AuthService } from '../../../appServices/auth/auth.service';
import { BasicInfoService } from '../../../appServices/basic-info/basic-info.service';
import { IBasicInfo } from '../../../appInterfaces/IBasicInfo';
import { RouterLink } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-otp-signup',
  standalone: true,
  imports: [
    OtpFieldComponent,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatSelectModule,
    MatCheckboxModule,
    RouterLink
  ],
  templateUrl: './otp-signup.component.html',
  styleUrl: './otp-signup.component.scss'
})
export class OtpSignupComponent implements OnInit {
  Form!: FormGroup;
  VerifySignupForm!: FormGroup;

  usernameType: any = '';
  validator:any;
  loading:boolean = false;
  hide : boolean = true;
  basicInfo!:IBasicInfo;
  otpSent: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private _fb: FormBuilder,
    private _du: DesignUtilityServices,
    private authService: AuthService,
    private basicInfoService: BasicInfoService,
    @Inject(MAT_DIALOG_DATA) public data: { userData: string } // Inject data
  ){
    this.validator = this._du.validator;
  }

  ngOnInit(): void {
    this.getBasicInfo();

    // Prepopulate mobile number from data
    if (this.data?.userData) {
      this.Form.get('mobileNumber')?.setValue(this.data.userData);
    }

    this.VerifySignupForm = this._fb.group({
      otp: ['', [Validators.required, Validators.minLength(4), Validators.pattern(this.validator.otp)]],
    });
  }



  checkUsername(){
    const username = this.authService.username.username;
    if (typeof +username === "number" && !isNaN(+username)) {
      this.usernameType = 'mobile';
      this.Form.get('mobileNumber')?.patchValue(username);
    } else {
      this.usernameType = 'email';
      this.Form.get('emailId')?.patchValue(username);
      // this.setCountryByGeo();
    }
  }

  /**
  * get basic info
  */
  getBasicInfo(): void {
    this.basicInfoService.basicInfo.subscribe(
      (res) => {
        if (res) {
          this.basicInfo = res;
          this.initForm();
        }
      }
    );
  }


  /**
   * init
   */
  initForm() {
    const baseForm = this._fb.group({
      firstName: ['', Validators.required],
      lastName: [''],
      mobileNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(this.validator.mobile),
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      userRole: [{ id: 2 }],
      emailId: [
        '',
        [Validators.required, Validators.pattern(this.validator.email)],
      ],
      password: ['', [Validators.required, Validators.minLength(4)]],
      terms: [false, Validators.requiredTrue],
    });

    this.Form = this.basicInfo?.businessType === 'cannabis'
      ? this._fb.group({
          ...baseForm.controls,
          dateOfBirth: ['', Validators.required],
          customerType: ['', Validators.required],
          mmjStateIDNumber: [''],
        })
      : baseForm;


    this.checkUsername();
  }


   /**
   * send otp
   */
   sendOtp(): void {
    if (this.Form.invalid) {
      return;
    }
    this.loading = true;

    const payload = {
      mobileNumber: this.Form.get('mobileNumber')?.value,
      emailId: this.Form.get('emailId')?.value,
    };



    this.authService.sendOtp(payload).subscribe(
      (res) => {
        console.log('OTP Sent');
        this.loading = false;
        this.otpSent = true;
      },
      (err) => {
        this.loading = false;
        console.warn(err);
      }
    );

  }



  getOtp(otp:string){
    // this.Form?.get('otp')?.patchValue(otp);

    if(otp !=='INVALID'){
      this.VerifySignupForm.get('otp')?.patchValue(otp);
      this.Form.get('password')?.patchValue(otp);
    }else{
      this.VerifySignupForm.get('otp')?.patchValue('');
      this.Form.get('password')?.patchValue('');
    }
  }


  onVerifySignup(){

    let payload = {
      ...this.Form.value,
      otp:this.VerifySignupForm.value.otp,
      userOtherInfoModel: {
        customerType: this.Form.value.customerType,
        mmjStateIDNumber: this.Form.value.mmjStateIDNumber
      },
    }

    delete payload.customerType;
    delete payload.mmjStateIDNumber;

    if (this.Form.value.dateOfBirth){
      const dob = this.Form.value.dateOfBirth ? this.convertToUTCString(this.Form.value.dateOfBirth) : null;
      payload.dateOfBirth = dob;
    }

    this.authService.signup(payload).subscribe(
      (res:any) => {
        console.log('Account created successfully.');

        const paylaodLogin = {
          userName:this.Form.value.mobileNumber,
          password:this.Form.value.password
        }

        this.onLoginSubmit(paylaodLogin);
        this.loading = false;
      },
      (err:any) => {
        this.loading = false;
        this._du.openCustomSnackBar('Signup credentials are invalid', 'Close', 'error');
      }
    );
  }




  /**
   * Convert date to UTC
   * @param date
   * @returns
   */
  convertToUTCString(timestamp:any) {
    if (timestamp != null) {
      const date = new Date(timestamp);
      const utcString = date.toUTCString();
      const timestp = new Date(utcString).getTime();
      return timestp;
    } else {
      return null;
    }
  }






  onLoginSubmit(paylaod:any){
    this.loading = true;
    if (this.Form.invalid) {
      return;
    }

    const data ={
      userName: paylaod.userName,
      password:paylaod.password,
      userRoleModel: {
        userRole :"customer"
      },
      asOTP:false
    };

    this.authService.login(data).subscribe(
    (res:any) =>{
      this.loading = false;
      this.authService.postLogin(res.token);
    },
    (err:any) =>{
      this.loading = false;
      this._du.openCustomSnackBar('Error: '+err, 'Close', 'error');
    });
  }





}
